export const compactDensity = {
  button: {
    xs: {
      px: "0.5rem",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      height: "1.5rem",
      minWidth: "1.5rem",
    },
    sm: {
      px: "0.75rem",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      height: "1.75rem",
      minWidth: "1.75rem",
    },
    md: {
      px: "1rem",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      height: "2rem",
      minWidth: "2rem",
    },
    lg: {
      px: "1rem",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      height: "2.5rem",
      minWidth: "2.5rem",
    },
    xl: {
      px: "1.5rem",
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
      height: "3rem",
      minWidth: "3rem",
    },
  },
  form: {
    field: {
      xs: {
        px: "0.5rem",
        py: "0",
        fontSize: "0.75rem",
        lineHeight: "1rem",
        height: "1.5rem",
      },
      sm: {
        px: "0.75rem",
        py: "0",
        fontSize: "0.75rem",
        lineHeight: "1rem",
        height: "1.75rem",
      },
      md: {
        px: "1rem",
        py: "0",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        height: "2rem",
      },
      lg: {
        px: "1rem",
        py: "0",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        height: "2.5rem",
      },
      xl: {
        px: "1.5rem",
        py: "0",
        fontSize: "1.125rem",
        lineHeight: "1.75rem",
        height: "3rem",
      },
    },
    element: {
      xs: {
        fontSize: "0.75rem",
        lineHeight: "1rem",
        height: "1.5rem",
        width: "1.5rem",
      },
      sm: {
        fontSize: "0.75rem",
        lineHeight: "1rem",
        height: "1.75rem",
        width: "1.75rem",
      },
      md: {
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        height: "2rem",
        width: "2rem",
      },
      lg: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        height: "2.5rem",
        width: "2.5rem",
      },
      xl: {
        fontSize: "1.125rem",
        lineHeight: "1.75rem",
        height: "3rem",
        width: "3rem",
      },
    },
  },
  switch: {
    sm: {
      width: "1.75rem",
      height: "0.875rem",
    },
    md: {
      width: "2rem",
      height: "1rem",
    },
    lg: {
      width: "3rem",
      height: "1.5rem",
    },
    xl: {
      width: "3.5rem",
      height: "1.75rem",
    },

    checked: {
      sm: {
        translateX: "0.875rem",
      },
      md: {
        translateX: "1rem",
      },
      lg: {
        translateX: "1.5rem",
      },
      xl: {
        translateX: "1.75rem",
      },
    },

    box: {
      sm: {
        width: "0.625rem",
        height: "0.625rem",
      },
      md: {
        width: "0.75rem",
        height: "0.75rem",
      },
      lg: {
        width: "1.25rem",
        height: "1.25rem",
      },
      xl: {
        width: "1.5rem",
        height: "1.5rem",
      },
    },
  },
};

export const comfortableDensity = {
  button: {
    xs: {
      px: "0.75rem",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      height: "1.75rem",
      minWidth: "1.75rem",
    },
    sm: {
      px: "1rem",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      height: "2rem",
      minWidth: "2rem",
    },
    md: {
      px: "1rem",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      height: "2.25rem",
      minWidth: "2.25rem",
    },
    lg: {
      px: "1rem",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      height: "2.75rem",
      minWidth: "2.75rem",
    },
    xl: {
      px: "1.5rem",
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
      height: "3.125rem",
      minWidth: "3.125rem",
    },
  },
  form: {
    field: {
      xs: {
        px: "0.75rem",
        py: "0",
        fontSize: "0.75rem",
        lineHeight: "1rem",
        height: "1.75rem",
      },
      sm: {
        px: "0.75rem",
        py: "0",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        height: "2rem",
      },
      md: {
        px: "0.75rem",
        py: "0",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        height: "2.25rem",
      },
      lg: {
        px: "1rem",
        py: "0",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        height: "2.75rem",
      },
      xl: {
        px: "1rem",
        py: "0",
        fontSize: "1.125rem",
        lineHeight: "1.75rem",
        height: "3.125rem",
      },
    },
    element: {
      xs: {
        fontSize: "0.75rem",
        lineHeight: "1rem",
        height: "1.75rem",
        width: "1.75rem",
      },
      sm: {
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        height: "2rem",
        width: "2rem",
      },
      md: {
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        height: "2.25rem",
        width: "2.25rem",
      },
      lg: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        height: "2.75rem",
        width: "2.75rem",
      },
      xl: {
        fontSize: "1.125rem",
        lineHeight: "1.75rem",
        height: "3.125rem",
        width: "3.125rem",
      },
    },
  },
  switch: {
    sm: {
      width: "2rem",
      height: "1rem",
    },
    md: {
      width: "2.25rem",
      height: "1.25rem",
    },
    lg: {
      width: "3rem",
      height: "1.5rem",
    },
    xl: {
      width: "3.5rem",
      height: "1.75rem",
    },

    checked: {
      sm: {
        translateX: "1rem",
      },
      md: {
        translateX: "1rem",
      },
      lg: {
        translateX: "1.5rem",
      },
      xl: {
        translateX: "1.75rem",
      },
    },

    box: {
      sm: {
        width: "0.75rem",
        height: "0.75rem",
      },
      md: {
        width: "1rem",
        height: "1rem",
      },
      lg: {
        width: "1.25rem",
        height: "1.25rem",
      },
      xl: {
        width: "1.5rem",
        height: "1.5rem",
      },
    },
  },
};
